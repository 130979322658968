export const environment = {
	production: false,
	msalConfig: {
		auth: {
			clientId: 'b848a978-4800-4818-9880-baa9c650ea1b',
			authority: 'https://login.microsoftonline.com/1a8d097b-24de-409d-bc68-e43dd69516bc'
		}
	},
	appVersion: 'v0.0.1',
	urlAudit: 'https://bvs-oportunidades-test-audit.azurewebsites.net',
	urlAuth: 'https://bvs-oportunidades-test-asauth.azurewebsites.net',
	urlAPI: 'https://bvs-oportunidades-test-core.azurewebsites.net',
	urlNotifications:'https://bvs-oportunidades-test-signals.azurewebsites.net',
	keyCaptca: '6LfbX9cpAAAAAE3zdW_kqv_sffUqZhXf7qn991Pp'
};
